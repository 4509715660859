<template>
  <div id="remittance" class="template-1">
    <header class="page-header container">
      <h1>{{ title }}</h1>
    </header>
    <div v-if="!allowPage">
      <security-banner :i18n="translations.components"></security-banner>
    </div>
    <div v-if="allowPage">
      <div class="container">
        <b-row>
          <b-col sm="5" v-if="$route.path.split('/')[4] === 's'" class="flush-left">
            <b class="campname">{{ stateUndist.camp_name }} - {{ stateUndist.camp_number }} </b>
            <br />
          </b-col>
          <b-col sm="5" v-if="$route.path.split('/')[4] === 'c'" class="flush-left">
            <b class="campname">{{ officerToolbarSelected.camp_name }}
              <span>{{ translations.tcCamp }}</span>
              - {{ officerToolbarSelected.camp_number }}
            </b>
            <br />
          </b-col>
        </b-row>
        <b-row class="subtitle" v-if="iCanSeeAny(secured_camp_remittance_add_controls)">
          <b-col sm="3" class="flush-left" style="padding: 0">
            <h3>{{ translations.tcSelectSource }}</h3>
          </b-col>
          <b-col sm="5">
            <Location ref="campDropdown" @camp_change="handleCampChange($event)" :locations="campList" :outside="false"
              :i18n="translations.components" :remittance="true" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-alert :show="dismissCountDown" dismissible :variant="alertVariant" @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged">{{ alertMessage }}</b-alert>
          </b-col>
        </b-row>
      </div>
      <b-form class="formClass">
        <div class="totals-section container">
          <vue-sticky-element stickMode="element-start">
            <div class="container total-section-container">
              <b-row>
                <b-col xs="0" sm="1"></b-col>
                <b-col xs="12" sm="5">
                  <div class="total">{{ translations.tcScriptureRemittanceDetailTotal }}</div>
                </b-col>
                <b-col xs="12" sm="5" class="amount">
                  <div class="total">{{ lineItemsScriptureTotal | toCurrency }}</div>
                </b-col>
                <b-col xs="0" sm="1"></b-col>
              </b-row>
              <b-row>
                <b-col xs="0" sm="1"></b-col>
                <b-col xs="12" sm="5" class="subtotal-text">
                  <div class="total">{{ translations.tcBarnabasRemittanceDetailTotal }}</div>
                </b-col>
                <b-col xs="12" sm="5" class="amount subtotal">
                  <div class="total">{{ lineItemsBarnabasTotal | toCurrency }}</div>
                </b-col>
                <b-col xs="0" sm="1"></b-col>
              </b-row>
              <b-row>
                <b-col xs="0" sm="1"></b-col>
                <b-col xs="12" sm="5" class="grand-total">
                  <div class="total">{{ translations.tcRemittanceDetailTotal }}</div>
                </b-col>
                <b-col xs="12" sm="5" class="grand-total amount">
                  <div class="total">{{ lineItemsTotal | toCurrency }}</div>
                </b-col>
                <b-col xs="0" sm="2"></b-col>
              </b-row>
              <div class="text-center">
                <label class="error-label" v-if="maxDonationExceeded">{{ translations.tcExceededMaxAmount }}</label>
              </div>
              <br />
              <b-row class="action-btn-row">
                <b-col class="actionBtns">
                  <b-button class="btn btn-tertiary col-sm-4" @click="handleCancelClick">
                    {{ translations.tcCancel }}
                  </b-button>
                </b-col>
                <b-col class="actionBtns">
                  <b-button class="btn btn-tertiary col-sm-4" @click="commenceCleanUp(false)">
                    {{ translations.tcClear }}
                  </b-button>
                </b-col>
                <b-col class="actionBtns">
                  <b-button v-if="iCanSee(secured_pending_transaction_save_controls.save_treasury_tool_pending_transaction_button)
                    " class="btn btn-primary col-sm-4" id="addSummaryModal"
                    :disabled="maxDonationExceeded || remittanceTimeRemaining(remittanceProcessedTime) > 0"
                    @click="prepareTheModal($event, 'addSummaryModal')" target="_blank" rel="noopener">
                    {{ translations.tcSave }}
                  </b-button>
                </b-col>
              </b-row>
              <br />
            </div>
          </vue-sticky-element>
        </div>
        <div class="remittance">
          <div class="container" v-for="(item, idx) in line.lineItems" :key="idx">
            <div>
              <h3 style="display:inline;" class="group">{{ translations[item.group] }}</h3>
              <span v-if="item.groupDetails != ''" v-html="translations[item.groupDetails]"></span>
            </div>
            <br />
            <span v-for="(account, index) in lineItemsDynamic(item)" :key="account.key">
              <div class="remittance-details">
                <div class="flex-container" v-if="account.category != 'dummyPlace'">
                  <div class="flex-left">
                    <b :class="account.class">{{ translations[account.category] }}</b>
                    <div class="flex-container" style="font-size:13px;display:block;" v-html="translations[account.text]">
                    </div>
                  </div>
                  <div class="flex-right">
                    <div v-if="account.currency" class="dollarsign">$</div>
                    <b-form-input class="currency-input" type="text"
                      :class="{ a80: account.currency, a86: !account.currency }" size="sm"
                      @click="handleAmountInputClick($event)" @blur="handleAmountInputLeave($event, account.currency)"
                      @keypress="account.currency ? onlyNumber($event) : onlyWholeNumber($event)"
                      @keyup="account.currency ? handleLeadingDecimalEntry($event) : ''"
                      @change="handleLineItemChange($event, account.category, account.key)" v-model="account.amount">
                    </b-form-input>
                  </div>
                </div>
              </div>
              <div class="new-row" v-if="(index + 1) % 2 == 0">
                <br />
                <br />
              </div>
            </span>
            <hr />
          </div>
        </div>
      </b-form>
      <AddSummary :modalTitle="campTitle" :multicamp="multicamp" :i18n="translations.components"
        :translations="translations" @continueToPayment="processPayment($event)" />
      <div style="height: 40px">&nbsp;</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, mapState } from 'vuex'
import AddSummary from '@/components/treasury-tools/add_summary'
import { alertMixin } from '@/mixins/alertMixin'
import { modalMixin } from '@/mixins/modalMixin'
import { numericMixin } from '@/mixins/numericMixin'
import { remittanceMixin } from '@/mixins/remittanceMixin'
import SecurityBanner from '@/components/SecurityBanner'
import { staffUserMixin } from '@/mixins/staffUserMixin'
import { translationMixin } from '@/mixins/translationMixin'
import { breakpoints } from '@/mixins/breakpoints'
import Location from '@/components/treasury-tools/location'

export default {
  components: {
    AddSummary,
    SecurityBanner,
    Location
  },
  name: 'e503-view',
  mixins: [numericMixin, remittanceMixin, modalMixin, alertMixin, translationMixin, staffUserMixin, breakpoints],
  props: {},
  data() {
    return {
      attention:
        'To restore words to a blank 503 form:  Click on the down arrow next to your name on the top ribbon.  Click the “LOGOUT” button.  Once completely out of theConnection, please log back in.',
      countTally: 0,
      line: {
        lineItems: []
      },
      multicamp: false,
      noCloseOnBackdrop: true,
      num: '-1',
      remittanceTally: 0.0,
      secured_camp_remittance_add_controls: {
        camp_remittance_multi_camp_dropdown_list: 'b2c9e3dd-6788-4a05-9056-efbc2769e848',
        state_remittance_multi_camp_dropdown_list: 'e766705a-188e-4872-8891-8fab16ffecf5'
      },
      secured_pending_transaction_save_controls: {
        save_treasury_tool_pending_transaction_button: '74a4e33c-0d9e-44eb-8907-101dffe59c31'
      },
      secured_tile_view_controls: {
        treasury_state_e503_check_cash_bulk_tile: '514e605c-a4f1-487b-988d-58987211dce2',
        treasury_camp_e503_check_cash_bulk_tile: '2c870158-65d4-48d0-bae1-c0175706b22d'
      },
      showTable: 0,
      summaryLineItems: [],
      summaryCounts: [],
      tableShow: 0,
      tally: 0.0,
      tdt: null,
      translations: {}
    }
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      campList: 'campModule/campList',
      currentCamp: 'campModule/currentCamp',
      iCanSee: 'user/iCanSee',
      iCanSeeAny: 'user/iCanSeeAny',
      officerToolbarSelected: 'user/officerToolbarSelected',
      remittanceModel: 'remittanceModule/remittanceModel',
      stateOrCamp: 'campModule/stateOrCamp',
      stateUndist: 'campModule/stateUndist'
    }),
    ...mapState({
      presentCamp: state => state.campModule.currentCamp,
      remittanceProcessedTime: state => state.remittanceModule.remittanceProcessedTime
    }),
    allowPage() {
      return (
        (this.stateOrCamp === 's'
          ? this.iCanSee(this.secured_tile_view_controls.treasury_state_e503_check_cash_bulk_tile)
          : this.iCanSee(this.secured_tile_view_controls.treasury_camp_e503_check_cash_bulk_tile)) &&
        ((!!this.stateUndist && !!this.stateUndist.cmp_org_key) || this.isStaffMember)
      )
    },
    title() {
      return this.stateOrCamp === 's' ? this.translations.tcE503StateCheckAndCashBulk : this.translations.tcE503CheckAndCashBulk
    },
    campTitle() {
      return '(' + this.currentCamp.value + ') ' + this.currentCamp.text
    },
    lineItemsBarnabasTotal() {
      let li_tally = 0.0
      this.line.lineItems.map(xl => {
        xl.accounts.map(xm => {
          if (xm.type === 'barnabas' && xm.currency) {
            xm.amount = parseFloat(xm.amount).toFixed(2) === 'NaN' ? 0 : xm.amount
            li_tally += parseFloat(xm.amount)
          }
        })
      })
      return li_tally.toFixed(2)
    },
    lineItemsScriptureTotal() {
      let li_tally = 0.0
      this.line.lineItems.map(xl => {
        xl.accounts.map(xm => {
          if (xm.type === 'scripture' && xm.currency) {
            xm.amount = parseFloat(xm.amount).toFixed(2) === 'NaN' ? 0 : xm.amount
            li_tally += parseFloat(xm.amount)
          }
        })
      })
      return li_tally.toFixed(2)
    },
    lineItemsTotal() {
      this.tally = 0.0
      this.line.lineItems.map(xl => {
        xl.accounts.map(xm => {
          if (xm.currency) {
            xm.amount = parseFloat(xm.amount).toFixed(2) === 'NaN' ? 0 : xm.amount
            this.tally += parseFloat(xm.amount)
          }
        })
      })
      return this.tally.toFixed(2)
    },
    maxDonationExceeded() {
      return this.maxAmtExceeded(this.tally.toFixed(2))
    },
    stateOrCampKey() {
      return this.stateOrCamp === 's' ? this.stateUndist.cmp_org_key : this.currentCamp.key
    }
  },
  methods: {
    ...mapActions({
      processRemittance: 'remittanceModule/processRemittance',
      savePendingTransaction: 'remittanceModule/savePendingTransaction',
      setCurrentCampByKey: 'campModule/setCurrentCampByKey',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPresentationSummaryItems: 'remittanceModule/setPresentationSummaryItems',
      setSummaryItems: 'remittanceModule/setSummaryItems'
    }),
    async commenceCleanUp(tf) {
      this.line.lineItems.map(l => {
        l.accounts.map(d => {
          d.amount = 0.0
        })
      })
      await this.setCurrentCampByKey(this.stateOrCampKey)
    },
    handleAmountInputLeave(evt, cur) {
      if (evt.target.value === '') {
        evt.target.value = cur ? '0.00' : '0'
      }
    },
    handleCancelClick() {
      this.commenceCleanUp()
      this.$router.push({ path: `/officers/camp/treasury-tools/${this.$route.path.split('/')[4]}` })
    },
    async handleCampChange(evt) {
      await this.setCurrentCampByKey(evt)
    },
    handleLineItemChange(evt, cat, idx) {
      this.line.lineItems.map((alpha, nx) => {
        alpha.accounts.map((beta, dx) => {
          if (beta.currency) {
            beta.amount = (beta.amount * 1.0).toFixed(2)
          }
        })
      })
    },
    lineItemsDynamic(item) {
      if ((this.isMobile() || this.isTablet()) && item.group == 'tcFaithFundDonations') {
        return [...item.accounts].sort((a, b) => a.order - b.order)
      }
      return item.accounts
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getViewTranslations(),
          await this.getComponentTranslations('add-summary', 'security-banner', 'tt-location', 'tt-outside-state')
        ]).then(results => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
          this.attention = this.translations.tcAttention
        })
        this.tdt = this.formattedDate()
        if (this.allowPage) {
          await (async () => {
            if (this.$route.path.split('/')[4] === 's') {
              this.setCurrentCampByKey(this.stateUndist.cmp_org_key)
            }
            this.tableShow = this.showTable
            this.line.lineItems = this.remittanceModel.transactions
          })()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
        if (this.remittanceTimeRemaining(this.remittanceProcessedTime) > 0) {
          await this.showRemittanceInProcessMessage()
        }
      }
    },
    prepareTheModal(evt) {
      this.summaryLineItems = []
      this.summaryCounts = []
      this.line.lineItems.map(alpha => {
        return alpha.accounts.map(beta => {
          if (beta.currency === true && beta.amount > 0.0) {
            this.summaryLineItems.push({
              group: alpha.group,
              category: beta.category,
              field: beta.field,
              amount: beta.amount,
              order: beta.order
            })
          }
        })
      })
      this.line.lineItems.map(gamma => {
        return gamma.accounts.map(delta => {
          if (delta.currency !== true && delta.amount > 0) {
            this.summaryCounts.push({
              category: delta.category,
              field: delta.field,
              amount: delta.amount,
              group: gamma.group
            })
          }
        })
      })
      let errorMessage = ''
      if (this.validateRemittance() === false) {
        errorMessage = this.translations.tcErrorInvalidValues
      } else if (this.summaryLineItems.length === 0) {
        errorMessage = this.translations.tcErrorDuringSave
      } else {
        this.setSummaryItems(this.summaryLineItems)
        this.setPresentationSummaryItems(this.summaryCounts)
        this.showModal(evt, 'addSummaryModal', 'summaryModal')
      }
      if (errorMessage.length > 0) {
        this.$swal({
          icon: 'error',
          text: errorMessage,
          confirmButtonText: this.translations.tcOk
        })
      }
    },
    async processPayment() {
      // let remitCampKey = this.currentCamp.key
      let remitCampKey = this.officerToolbarSelected.camp
      const rmtDate = this.formattedDate(new Date())
      this.$swal({
        icon: 'success',
        text: this.translations.tcRemittanceProcessingMessage,
        confirmButtonText: this.translations.tcOk
      }).then(result => {
        this.commenceCleanUp()
        this.$router.push({ path: `/officers/camp/treasury-tools/${this.stateOrCamp}` })
      })
      await this.savePendingTransaction({
        key: '',
        date: rmtDate,
        rmd: '',
        multicamp: this.multicamp,
        rmtCampKey: remitCampKey
      })
    },
    async showRemittanceInProcessMessage() {
      let timeRemaining = this.remittanceTimeRemaining(this.remittanceProcessedTime)
      this.$swal({
        icon: 'error',
        text: this.translations.tcRemittanceInProcessMessage + timeRemaining + ' ' + this.translations.tcMinutes,
        confirmButtonText: this.translations.tcOk
      })
    },
    validateRemittance() {
      this.remittanceTally = 0.0
      this.countTally = 0
      this.line.lineItems.map(l => {
        l.accounts.map(d => {
          if (d.currency) {
            let amt = d.amount == '' ? '0.00' : d.amount
            this.remittanceTally += parseFloat(amt)
          } else {
            let count = d.amount == '' ? '0' : d.amount
            this.countTally += parseInt(count, 10)
          }
        })
      })
      return this.remittanceTally > 0.0 && this.countTally > -1
    }
  }
}
</script>

<style scoped>
.btn {
  min-width: 180px;
  border-radius: 4px;
}

.campname {
  font-size: 22px;
}

.error-label {
  color: red;
}

.totals-section {
  margin-top: 15px;
  margin-bottom: 75px;
}

.total-section-container {
  background: #fcfcfcfc;
  border: 3px solid #80808080;
  padding-top: 10px;
  margin-bottom: 40px;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: lightgrey !important;
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

/*This setting is to hide the up/down arrows, in web browsers, on the number input*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.dollarsign {
  position: relative;
  height: 0;
  left: -20px;
  font-size: 25px;
  top: -3px;
}

.subtitle {
  margin: 10px 0;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

h3.dtTitle {
  text-transform: uppercase;
}

.flex-container {
  display: flex;
  flex-direction: row;

  @include breakpoint(sm) {
    flex-direction: column;
  }
}

.new-row {
  display: inline-block;
  margin-bottom: 10px;
}

.flex-left {
  width: 75%;

  @include breakpoint(sm) {
    width: 100%;
  }
}

.flex-right {
  width: 25%;

  @include breakpoint(sm) {
    width: 75%;
    margin-left: 20px;
  }
}

.subtotal {
  border-bottom: 1px solid black;
}

.subtotal-text {
  border-bottom: 1px solid black;

  @include breakpoint(sm) {
    border-bottom: none;
  }
}

.grand-total {
  margin-top: 15px;
}

.grand-total div {
  font-weight: bold;
}

.amount {
  text-align: right;
}

.remittance-details {
  vertical-align: top;
  width: 50%;
  display: inline-block;
}

.currency-input {
  width: 80%;
  display: inline-block;
  border-radius: 4px;
}

.total {
  font-size: 20px;
}

.actionBtns {
  text-align: center;
}

.vue-sticky-element {
  z-index: 1000;
}

.vue-sticky-element--stuck {
  transform: translateY(0) !important;
  right: 0;
  left: 0;
  border-radius: 5px;
}

@media (max-width: 400px) {
  .total {
    font-size: 20px;
  }

  .dollarsign {
    font-size: 20px;
  }
}

@media (max-width: 700px) {
  .remittance-details {
    width: 100% !important;
  }

  .btn {
    min-width: 126px !important;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 0px;
  }

  .new-row {
    display: none !important;
  }
}
</style>
